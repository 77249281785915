<template>
  <vue-recaptcha @verify="changeVerify" ref="invisibleHcaptcha" :loadRecaptchaScript="true" :sitekey="getEnvCaptcha()">
    <slot></slot>
  </vue-recaptcha>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    VueRecaptcha
  },
  methods: {
    getEnvCaptcha(){
      return process.env.VUE_KEY_CAPTCHA
    },
    resetCaptcha(){
      this.$refs.invisibleHcaptcha.reset();
    },
    setCaptcha(){
      this.$refs.invisibleHcaptcha.execute();
    },
    async changeVerify(e) {
      this.$emit("changeVerify", e);
    },
  },
};
</script>